import axios from 'axios';
import getApiUrl from 'helpers/apiUrls'
import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';

export const getOfficerTitleList = (data, dispatch, callback) => {

	const url = getApiUrl('officertitle', 'getOfficerTitleList');
	dispatch(commonActions.setLoader(true));

	axios.get(url + '/' + data.officerTitleId + '/' + data.status)
		.then((response) => {

			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case	
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7016'));
			callback();
		});
};

export const getOfficerTitleDetail = (dispatch, req, callback) => {
	const url = getApiUrl('officertitle', 'getOfficerTitleList') + '/' + req.id + '/null';
	const urllevel = getApiUrl('officertitle', 'getOfficerCommitteeTypeLevel');
	dispatch(commonActions.setLoader(true));
	Promise.all([axios.get(url), axios.get(urllevel)])
	.then(([response, responseLevel]) => {
		dispatch(commonActions.setLoader(false));
		let officerTitleData = { officerTitileInfo: {}, committeeInfo: [] };
		officerTitleData.committeeInfo = responseLevel.data.content || []
		if (response.data.status) {
			officerTitleData.officerTitileInfo = response.data.content || {};
			dispatch({ type: type.VIEW_OFFICER_TITLE, officerTitleData })
			callback(officerTitleData.committeeInfo);
		} else {
			// Handle error case	
			dispatch(commonActions.setMessage(true, response.data.message));
		}

	})
	.catch((err) => {
		dispatch(commonActions.setLoader(false));
		dispatch(commonActions.setMessage(true, '7018'));
	})
}

export const addOfficerTitle = (dispatch, req, callback) => {
	const url = getApiUrl('officertitle', 'addOfficerTitle');
	dispatch(commonActions.setLoader(true));

	axios.post(url, req)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '5008'));
			}
			// Handle error case
			else if (response.data.message === 2226) {
				dispatch({ type: type.ADD_OFFICER_TITLE, isDuplicateOfficerTitle: true })
			} else if (response.data.message === '103') {
				dispatch(commonActions.setMessage(true, '7041'));
			}
			else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7019'));
		});
};


export const editOfficerTitle = (dispatch, appObj, callback) => {

	const url = getApiUrl('officertitle', 'editOfficerTitle');
	dispatch(commonActions.setLoader(true));

	axios.put(url, appObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '5009'));
				callback();
			}
			// Handle error case
			else if (response.data.message === 2226) {
				dispatch({ type: type.EDIT_OFFICER_TITLE, isDuplicateOfficerTitle: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7020'));
		});
};
const editResponsibility = (dispatch, req, callback) => {
	const url = getApiUrl('officertitle', 'editResponsibility');
	dispatch(commonActions.setLoader(true));
	axios.put(url, req)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '5017'));
				dispatch({ type: type.EDIT_RESPONSIBILITY, isDuplicateOfficerTitle: false })
				callback();
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7043'));

		});
}
const getResponsibility = (dispatch, req, callback) => {
	const url = getApiUrl('officertitle', 'getResponsibility');
	dispatch(commonActions.setLoader(true));
	axios.get(url + '/' + req.id + '/' + req.status)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {

				if (response.data.content && response.data.content.length > 0) {
					callback(response.data.content);
				} else {
					dispatch(commonActions.setMessage(true, '2227'));
				}
			} else {

				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7043'));

		});
}
const postResequence = (dispatch, req, callback) => {

	const url = getApiUrl('officertitle', 'postResequence');
	dispatch(commonActions.setLoader(true));
	axios.post(url, req).then((response) => {
		dispatch(commonActions.setLoader(false));
		if (response.data.status) {
			callback();
		} else {
			dispatch(commonActions.setMessage(true, response.data.message));
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7059'));
		});
}
const getOfficerResponsebilityCommitteeTypeLevel = (req, dispatch, callback) => {
	const url = getApiUrl('officertitle', 'getResponsibility') + `/${req.id}/${req.status}`;
	const urllevel = getApiUrl('officertitle', 'getOfficerCommitteeTypeLevel');
	dispatch(commonActions.setLoader(true));
	Promise.all([axios.get(url), axios.get(urllevel)]).
		then(([response, responseLevel]) => {
			dispatch(commonActions.setLoader(false));
			let responseData = { responsibility: [], committeeInfo: [] };
			if (response.data.status) {
				responseData.responsibility = response.data.content || []
			}

			if (responseLevel.data.status) {
				responseData.committeeInfo = responseLevel.data.content || []
			}

			callback(responseData);
		})
		.catch(() => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7059'))
		})
}

const postOfficerTitleCommitteeImpact = (data, dispatch, callback) => {
	const url = getApiUrl('officertitle', 'OfficerTitleCommitteeImpact');
	dispatch(commonActions.setLoader(true));
	axios.post(url, data).then((response) => {
		dispatch(commonActions.setLoader(false));
		if (response.data.status) {
			callback(response.data.content);
		} else {
			dispatch(commonActions.setMessage(true, response.data.message));
		}
	}).catch((err) => {
		dispatch(commonActions.setLoader(false));
		dispatch(commonActions.setMessage(true, '7042'));
	});
}
export const getOfficerTitleAlreadyExists = (data, dispatch, callback) => {

	const url = getApiUrl('officertitle', 'OfficerTitleAlreadyExists') + `/${data.title}/${data.id}`;
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				dispatch({ type: type.ADD_OFFICER_TITLE, isDuplicateOfficerTitle: response.data.content > 0 || false })
				callback(response.data.content > 0 || false);
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));

		});
};
export default {
	getOfficerTitleList,
	getOfficerTitleDetail,
	getOfficerResponsebilityCommitteeTypeLevel,
	addOfficerTitle,
	editOfficerTitle,
	editResponsibility,
	getResponsibility,
	postResequence,
	postOfficerTitleCommitteeImpact,
	getOfficerTitleAlreadyExists
}
