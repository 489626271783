import React from 'react';

class tableHeader extends React.Component {
    constructor(props) {
        super(props);
        const self = this;
        self.state = {
            headerList: self.props.headerProps
        }
        self.onHeaderClick = self.onHeaderClick.bind(self)

    }

    onHeaderClick = (event, index, item) => {
        let self = this;
        let header = self.state.headerList;
        header.map((item, _index) => {
            if (_index != index) {
                if (item.orderBy >= 0) {
                    item.orderByIcon = 0;
                    item.orderBy = 0;
                }

            }
        });
        header[index].orderByIcon = (header[index].orderByIcon == 0 ? 1 : header[index].orderByIcon == 1 ? 2 : 1);
        header[index].orderBy = header[index].orderBy == 0 ? 1 : 0;
        self.setState({ headerList: header })
        this.props.onClickProps(event, item)
    }

    render() {
        const { headerList } = this.state

        return (


            headerList.map((item, index) => {
                if (item.orderBy >= 0) {
                    if (item.width) {
                        return <th key={index} width={item.width} className={item.hasSorting ? 'pointer ' + item.className : item.className} onClick={e => this.onHeaderClick(e, index, item.sortKey)} data-order={item.orderBy}>
                            {item.title}

                            {item.hasSorting && (
                                <i aria-hidden="true" className={item.orderByIcon == 0 ? 'sort icon' : item.orderByIcon == 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>

                            )}
                        </th>
                    }
                    return <th key={index} className={item.hasSorting ? 'pointer ' + item.className : item.className} onClick={e => this.onHeaderClick(e, index, item.sortKey)} data-order={item.orderBy}>
                        {item.title}

                        {item.hasSorting && (
                            <i aria-hidden="true" className={item.orderByIcon == 0 ? 'sort icon' : item.orderByIcon == 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>

                        )}
                    </th>


                }
                if (item.width) {
                    return <th key={index} width={item.width} className={item.className} data-order={item.orderBy}>
                        {item.title}

                        {item.hasSorting && (
                            <i aria-hidden="true" className={item.orderByIcon == 0 ? 'sort icon' : item.orderByIcon == 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>

                        )}
                    </th>
                }
                return <th key={index} className={item.className} data-order={item.orderBy}>
                    {item.title}

                    {item.hasSorting && (
                        <i aria-hidden="true" className={item.orderByIcon == 0 ? 'sort icon' : item.orderByIcon == 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>

                    )}
                </th>
            })
        );
    }
}

export default tableHeader;
