import Dragdrop from 'components/atoms/Dragndrop';
// Custom components
import TableHeader from 'components/atoms/Table/tableHeader';
import AddMembershipFaqModal from 'components/modals/Membership/Faq/addFaqModal';
import EditMembershipFaqModal from 'components/modals/Membership/Faq/editFaqModal';
import ViewMembershipFaqModal from 'components/modals/Membership/Faq/viewFaqModal';
import * as header from 'models/Membership/faqModels';
import React, { Component } from "react";
import { Confirm } from "semantic-ui-react";



class MembershipFaqs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            faqList: [],
            isConfirm: false
        }
    }

    componentDidMount() {
        this.getFaqList();
    }

    getFaqList = () => {
        this.setState({
            loading: true
        })
        this.props.getFaqList(this.setFaqListData);
    }

    setFaqListData = (response) => {

        this.setState({
            faqList: response,
            loading: false
        });
    }

    openViewModal = (item) => {
        this.setState({
            showViewModal: true,
            selectedFaqItem: item
        });
    }

    openEditModal = (faqId) => {

        this.props.getFaqDetails(faqId, () => {
            this.setState({
                showViewModal: false,
                showEditModal: true
            })
        });
    }

    closeModal = _type => {
        let self = this;

        switch (_type) {
            case 1:
                self.setState({ showEditModal: false });
                self.getFaqList();
                break;
            case 2:
                self.setState({ showViewModal: false });
                break;
            case 3:
                self.setState({ showAddModal: false });
                self.getFaqList();
                break;
            default:
                self.setState({ showEditModal: false })
        }
    }

    resequenceItems = (items) => {
        // Map the items into required format
        let faqsList = [];

        for (let i = 0; i < items.length; i++) {
            let obj = {};
            obj.FAQId = items[i].FAQId;
            obj.DisplayOrder = i + 1;
            obj.TypeId = items[i].TypeId;
            faqsList.push(obj);
        }
        this.props.resequenceFaqs(faqsList);
    }

    deleteFAQ = (faqId) => {
        this.setState({
            isConfirm: true,
            toDeleteFaqId: faqId
        })
    }

    handleCancel = () => this.setState({ isConfirm: false })

    handleConfirm = () => {

        let self = this;
        self.setState({ isConfirm: false });
        this.props.deleteFAQ((this.state.toDeleteFaqId), () => {
            self.getFaqList();

        });
    }
    onSort = (event, sortKey) => {
        // Definition not required
    }

    render() {
        const { loading, faqList, showAddModal, showViewModal, showEditModal, isConfirm } = this.state;
        const { userPermission } = this.props;
        return (
            <div className="homePage" data-testid="membershipFaqsCmp">
                <div className="ui">
                    <div className="headingTitle clearfix">
                        <h2>Membership FAQs</h2>
                        {userPermission.Add &&
                            <button onClick={() => { this.setState({ showAddModal: true }) }}
                                className="ui secondary button"><i aria-hidden="true" class="plus icon"></i>
                                Add Faq</button>}
                    </div>
                    <div className="contentWrapper clearfix">
                        <div className="tableWrapper">
                            <table className="customTable faqTable" data-testid = "membershipFaqTable">
                                <thead>
                                    <tr>
                                        <TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>

                                        {userPermission.Delete && <th className="action">
                                            Action
                                        </th>}
                                    </tr>
                                </thead>
                                {(!loading && (faqList && faqList.length > 0)) && (
                                    <Dragdrop items={faqList} openViewModal={(item) => { this.openViewModal(item) }}
                                        resequenceItems={(items) => { this.resequenceItems(items) }}
                                        allowResequenceing={userPermission.Update}
                                        allowDelete={userPermission.Delete}
                                        deleteFAQ={(faqId) => { this.deleteFAQ(faqId) }} />
                                )}
                                {(!loading && (!faqList || faqList.length <= 0)) && (
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">{this.props.noRecordMessage}</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                {showAddModal && (<AddMembershipFaqModal {...this.props} closeModal={(type) => { this.closeModal(type) }} addFaq={this.props.addFaq} />)}
                {showViewModal && (<ViewMembershipFaqModal {...this.props} selectedItem={this.state.selectedFaqItem}
                    closeModal={(type) => { this.closeModal(type) }}
                    openEditModal={(faqId) => { this.openEditModal(faqId) }}
                    userPermission={userPermission} />)}
                {showEditModal && (
                    <EditMembershipFaqModal {...this.props} closeModal={(type) => { this.closeModal(type) }} />
                )}
                <Confirm className="confirmBox" cancelButton='No' confirmButton="Yes" content='Are you sure you want to delete this FAQ?'
                    onCancel={this.handleCancel} onConfirm={this.handleConfirm} open={isConfirm} />
            </div>
        );
    }
}

export default MembershipFaqs;

