import { MODULES, NAVIGATION } from 'models/Common/commonModels';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { allowPermissionForAuth, filterPermissionUrl } from '../../../helpers/utilCommon';


class AccordionMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeSubItem: '',
      sideMenu: NAVIGATION
    }
  }

  componentDidMount() {
    this.loadSideMenu();
  }

  toggelHeader = (index, isOpen, hasNavChild, itemKey) => {
    let SIDEMENU = this.state.sideMenu;
    if (hasNavChild) {
      SIDEMENU[index].isShow = !isOpen;
    } else {
      SIDEMENU.map((item, filterIndex) => { item.isShow = (item[MODULES[filterIndex]].length === 0) ? false : item.isShow; });
      SIDEMENU[index].isShow = true;
      this.setState({ activeSubItem: '' });
      this.props.history.push('/' + itemKey);
    }

    this.setState({ sideMenu: SIDEMENU })
  }

  activeSubheader = (routeUrl, _item) => {
    const { sideMenu } = this.state;
    sideMenu.map((item, index) => { item.isShow = (item[MODULES[index]] && item[MODULES[index]].length === 0) ? false : item.isShow })
    this.setState({
      sideMenu: sideMenu,
      activeSubItem: _item
    });
    this.props.history.push(`/${routeUrl}`);
  }

  loadSideMenu = () => {
    let currentRoute = this.props.history.location.pathname;
    currentRoute = currentRoute.substring(1); // Get the key from the route
    let SIDEMENU = this.state.sideMenu;
    let activeItem = '';
    let isParentModulePermission = [];
    if (SIDEMENU && SIDEMENU.length > 0) {
      SIDEMENU.map((item, index) => {

        isParentModulePermission = this.validateParentModulePermission(item, index);
        // Check Perent Module Permission
        if (isParentModulePermission && isParentModulePermission.length === 0) {
          item.isVisibleOnNavigation = false;
        }
        // Check Sub Module Permission
        this.validateSubModulePermission(item, index);
        // Check Expand and Collapse
        activeItem = this.validateToggleMenuShow(item, index, currentRoute);
      });
    }
    this.setState({ sideMenu: SIDEMENU, activeSubItem: activeItem })
  }

  // Validate Toggle Menu isShow
  validateToggleMenuShow = (item, index, currentRoute) => {
    let activeSubItem = '';
    if (item[MODULES[index]] && item[MODULES[index]].length === 0 && item.routeUrl === currentRoute) {
      item.isShow = true;
    } else if (item[MODULES[index]] && item[MODULES[index]].length > 0) {
      item[MODULES[index]].map(subItem => {
        if (subItem.key == currentRoute) {
          item.isShow = true;
          activeSubItem = subItem.title;
        }
      });
    }
    return activeSubItem;
  }

  // Validate Parent Module Permission
  validateParentModulePermission = (parentItem, index) => {
    if (parentItem[MODULES[index]].length > 0) {
      return parentItem[MODULES[index]].filter((result) => allowPermissionForAuth(filterPermissionUrl(result)));
    }
    return []
  }

  // Validate Sub Module Permission
  validateSubModulePermission = (parentItem, index) => {
    if (parentItem[MODULES[index]].length > 0) {
      parentItem[MODULES[index]].map((result) => {
        if (!allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod))) {
          result.isVisibleOnNavigation = false;
        }
      });
    }
  }


  // Render Parent Menu
  renderParentMenu = (item, index) => {
    return (
      item.isVisibleOnNavigation &&
      <p className={item.isShow && item.hasNavChild ? 'menuHeaderLabel active' : 'menuHeaderLabel'} onClick={e => this.toggelHeader(index, item.isShow, item.hasNavChild, item.routeUrl)}>
        <i aria-hidden="true" className={item.iconClass} ></i> {item.ModuleName}
        {item[MODULES[index]].length > 0 && item.hasNavChild &&
          < i aria-hidden="true" className={item.isShow ? 'chevron up  icon' : 'chevron down icon'}></i>
        }
      </p>
    )
  }

  // Render Child Menu
  renderChildMenu = (item, index, activeSubItem) => {

    return (
      <ul className="subMenu">
        {item[MODULES[index]].map((subitem, _index) => {
          return (
            subitem.isVisibleOnNavigation &&
            <li key={_index} className={activeSubItem === subitem.title ? 'active' : ''} onClick={e => this.activeSubheader(subitem.routeUrl, subitem.title)} > {subitem.title} </li>
          )
        })
        }
      </ul>
    )
  }

  render() {
    const { sideMenu, activeSubItem } = this.state;
    return (
      <div className="menuWrapper">
        {sideMenu.map((item, index) => {
          return <div key={index} className="menuWrapper">
            <React.Fragment>
              {this.renderParentMenu(item, index)}
              {item[MODULES[index]].length > 0 && item.isShow && item.hasNavChild &&
                this.renderChildMenu(item, index, activeSubItem)
              }
            </React.Fragment>
          </div>
        })}
      </div>

    )
  }
}

export default withRouter(AccordionMenu);
