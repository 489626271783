export const HEADERINFO =
  [{ 'title': 'Section Number', 'hasSorting': true, 'className': "membershipName", "sortKey": 'SectionNumber', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Volume Number', 'hasSorting': true, 'className': "membershipFee", 'sortKey': 'VolumeNumber', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'BOS Volume Location', 'hasSorting': true, 'className': "isEnabled", 'sortKey': 'BosVolumeLocation', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Comments', 'hasSorting': false }
  ]

export const RefreshHeader = () => {
  HEADERINFO.map(item => {
    item.orderBy = 0;
    item.orderByIcon = 0;
  });
}
export const VolumeObj = {
  SectionNumber: '',
  VolumeNumber: "",
  Comments: ""
}

export const ItemsPerPage = [
  { key: 1, text: "25", value: 25 },
  { key: 2, text: "50", value: 50 },
  { key: 3, text: "75", value: 75 },
  { key: 4, text: "100", value: 100 }
]