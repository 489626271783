import Dashboard from 'components/pages/Dashboard/dashboard.Component';
import CommitteeList from 'containers/Committee/committeeTypeContainer';
import ViewCommitteeTypeForm from 'containers/Committee/committeeTypeDetailsContainer';
import MeetingTypeList from 'containers/Committee/meetingTypeContainer';
import OfficerTitleList from 'containers/Committee/officerTitleContainer';
import BOSVolumeComponent from 'containers/Committee/bosVolumeContainer';
import Home from 'containers/Layout/layoutContainer';
// Components
import Login from 'containers/Login/loginContainer';
import ClassificationList from 'containers/Membership/classificationContainer';
import MembershipDocumentList from 'containers/Membership/documentContainer';
import MembershipFaqsList from 'containers/Membership/faqContainer';
import MembershipType from 'containers/Membership/MembershipType/membershipTypeContainer';
import AddMemberShip from 'containers/Membership/MembershipType/membershipTypeViewContainer';
import UserList from 'containers/User/userContainer';
import MembershipTemplateList from 'containers/Membership/membershipTemplateContainer';
import AddUserForm from 'containers/User/userDetailsContainer';
import React from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

export const AppRoutes = ({ defaultUrl, isLoginByAzureAD, ...props }) => (
    <Switch>
        {defaultUrl && <Redirect exact from="/" to={defaultUrl} />}
        {!isLoginByAzureAD && <Route path='/login' component={Login} />}
        <Home {...props} isLoginByAzureAD={isLoginByAzureAD}>
            <div className="App">
                <Route path='/faq-list' component={MembershipFaqsList} />
                <Route path='/membershiptemplate' component={MembershipTemplateList} />
                <Route path='/membership-type' exact component={MembershipType} />
                <Route path='/membership-type/details/:MembershipTypeId?' exact component={AddMemberShip} />
                <Route path='/membership-type/add' exact component={AddMemberShip} />

                <Route path='/document-list' component={MembershipDocumentList} />
                <Route path='/classification-list' component={ClassificationList} />
                <Route path='/officer-title-list' component={OfficerTitleList} />
                <Route path='/user' exact component={UserList} />
                <Route path='/user/details/:UserId?' exact component={AddUserForm} />
                <Route path='/user/add' exact component={AddUserForm} />

                <Route path='/home' component={Dashboard} />
                <Route path='/committee-list' component={CommitteeList} />
                <Route path='/committee-type/details/:CommitteeTypeId?' exact component={ViewCommitteeTypeForm} />
                <Route path='/committee-type/add' exact component={ViewCommitteeTypeForm} />
                <Route path='/meeting-list' component={MeetingTypeList} />
                <Route path='/bos-volume/:pageType/:bosId?' component={BOSVolumeComponent} />

            </div>
        </Home>

    </Switch>
)
export default AppRoutes
