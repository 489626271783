import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';
import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import * as lodash from 'lodash';

const userList = () => {
    return {
        type: type.USER_LIST
    };
}

export const getUserList = (dispatch, callback) => {

    const url = getApiUrl('usermanage', 'getuserList');
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(userList());
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback();
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7002'));
            callback();
        });
};
const roleList = (data) => {
    return {
        type: type.USER_ROLE_LIST,
        payload: data
    };
}

const userAdd = () => {
    return {
        type: type.USER_ADD,
        payload: ''
    }
}
const userView = (data) => {
    return {
        type: type.USER_VIEW,
        payload: data
    }
}
const privilegeListByUser = (data) => {
    return {
        type: type.USER_PRIVILEGE_BY_USER,
        payload: data
    };
}
const userDetailWithPrivilege = (data) => {
    return {
        type: type.USER_DETAIL_WITH_PRIVILEGE,
        payload: data
    };
}
const userValidateInAd = (data) => {
    return {
        type: type.USER_VALIDATE_IN_AD,
        payload: { userInfo: data.userInfo }
    }
}
const userChangePrivilege = (data) => {
    return {
        type: type.USER_CHANGE_PRIVILEGE,
        payload: data
    }
}
const userDuplicate = (data) => {
    return {
        type: type.USER_ISDUPLICATE,
        payload: data
    }
}
const userEditSubmited = () => {
    return {
        type: type.USER_EDIT_SUBMITED,
        payload: ''
    }
}
export const getRoleList = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getRoleList');
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(roleList(response.data.content));
                callback();
            } else {
                // Handle error case
                dispatch({ type: type.SHOW_NOT_FOUND_PAGE }) // Code : - 6007
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7029'));

        });
};

// export const getdefaultPrivilege = (dispatch, req, callback) => {

//     const roleUrl = getApiUrl('usermanage', 'getDefaultPrivilege');
//     const privilegeUrl = getApiUrl('usermanage', 'getPrivilegeByRole');
//     dispatch(commonActions.setLoader(true));
//     axios.all([
//         axios.get(roleUrl),
//         axios.get(privilegeUrl + '/' + req.roleid)

//     ]).then(axios.spread((roleresponce, privilegeresponse) => {

//         dispatch(commonActions.setLoader(false));
//         if (roleresponce.data.status && privilegeresponse.data.status) {

//             dispatch(defaultPrivilegeList({ roleList: roleresponce.data.content, privilege: privilegeresponse.data.content }));
//             callback();
//         } else {
//             // Handle error case
//             dispatch(commonActions.setMessage(true, '6008'));
//         }
//     }))
//         .catch((err) => {
//             dispatch(commonActions.setLoader(false));
//             dispatch(commonActions.setMessage(true, '7031'));

//         });
// };
export const getPrivilegeByRole = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getPrivilegeByRole');
    dispatch(commonActions.setLoader(true));

    axios.get(url + '/' + req.roleId)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, '6009'));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7032'));

        });
};
export const getPrivilegeByUser = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getPrivilegeByUser');
    dispatch(commonActions.setLoader(true));

    axios.get(url + '/' + req.userId)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(privilegeListByUser(response.data.content));
                callback();
            } else {
                // Handle error case				
                dispatch(commonActions.setMessage(true, '6010'));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7033'));

        });
};
export const getUserdetail = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getUserDetail');
    dispatch(commonActions.setLoader(true));

    axios.get(url + '/' + req.userName)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                if (req.type == 1) {
                    dispatch(userDuplicate(response.data.content !== null ? { isDuplicate: true, errorCode: 2500 } : { isDuplicate: false, errorCode: '' }));
                    callback();
                } else {
                    dispatch(userDetailWithPrivilege(response.data.content));
                }

            } else {
                // Handle error case				
                dispatch(commonActions.setMessage(true, '6011'));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7030'));

        });
};
export const UserPrivilageSubmit = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'usermanagement');
    dispatch(commonActions.setLoader(true));

    axios.post(url, req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '5015'));
                callback();
            } else {
                // Handle error case		
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7035'));

        });
};
export const getUserdetailwithPrivilage = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getuserPrivilege');
    dispatch(commonActions.setLoader(true));
    axios.get(url + '/' + req.userId)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status && response.data.content) {
                dispatch(userView(response.data.content))
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch({ type: type.SHOW_NOT_FOUND_PAGE });
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7030'));

        });
};

// For updating user privilege data
export const putUserPrivilage = (dispatch, req, callback) => {
    req.UserPrivelege.map((item) => { return item.SubModule.map((_item) => _item.Privilege = _item.Privilege.filter(e => e.PrivilegeId !== -1)) })
    const url = getApiUrl('usermanage', 'putuserPrivilege');
    dispatch(commonActions.setLoader(true));

    axios.put(url, req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '5016'));
                dispatch(userEditSubmited())
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7037'));

        });
};

export const getUserListOnSearch = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getUserListOnSearch');
    dispatch(commonActions.setLoader(true));

    axios.get(url + '/' + req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback();
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7002'));
            callback();
        });
};
export const getValidateUser = (dispatch, req, callback) => {

    const url = getApiUrl('usermanage', 'getUserValidate');
    dispatch(commonActions.setLoader(true));

    axios.get(url + '/' + req.userName)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(userDuplicate((response.data.content !== null && response.data.content.RoleId > 0) ? { isDuplicate: true, errorCode: 2500, userId: response.data.content.UserId } : { isDuplicate: false, errorCode: '', userId: response.data.content.UserId }));
                callback(lodash.get(response.data.content, 'AzureAdUser'));

            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, '6011'));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7038'));

        });
};

export const updateUserPassword = (dispatch, req, callback) => {
    const url = getApiUrl('usermanage', 'updateUserPassword');
    dispatch(commonActions.setLoader(true));

    axios.put(url, req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '5024'));
                callback(true);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, '2513'));
                callback(false);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '2513'));

        });
};
export default {
    getRoleList,
    getPrivilegeByRole,
    getPrivilegeByUser,
    getUserdetail,
    UserPrivilageSubmit,
    getUserList,
    userValidateInAd,
    userAdd,
    userChangePrivilege,
    getUserdetailwithPrivilage,
    putUserPrivilage,
    getUserListOnSearch,
    getValidateUser,
    updateUserPassword
}
