import * as type from 'actionTypes';
import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import { cacheManager, clearAzureAdAuthCodeAction, cookiesManager, callAPI, encrypt, decrypt, noCookiesRedirectToLogin } from '../helpers/utilCommon';
import { cookieKeysEnum } from 'models/Common/commonModels';

export const hideMessage = (dispatch) => {
  dispatch(setMessage(false, '200'));
}

export const setLoader = (flag) => ({
  type: type.SET_LOADER,
  flag
});

export const setMessage = (flag, code) => ({
  type: type.SET_MESSAGE, flag, code
});

export const logout = (dispatch) => {
  let url = getApiUrl('login', 'logout');
  dispatch(setLoader(true));

  axios.post(url)
    .then((response) => {
      if (response.data.status) {
        // Clear all cache from react app.
        cacheManager.clearSession();
        // Validate if Logout from Azure AD.
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
          cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
          setTimeout(() => { window.location.assign(clearAzureAdAuthCodeAction()) }, 500)
        } else {
          window.location.assign('/login');
        }
      } else {
        dispatch(setMessage(true, response.data.message));
      }
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(setLoader(false));
      noCookiesRedirectToLogin(); // Default exception error
    });
}

export const getPermissionOnPageAction = (Input, groupInput) => ({
  type: type.ALLOW_PERMISSION_FOR_PAGE,
  Input,
  groupInput
});

// TODO : initiate Azure Request
export const initiateAzureRequestAction = (code, sessionState, authState, cb, dispatch) => {
  // TODO : Start code for init azure request.
  dispatch(setLoader(true));
  // TODO : Call Request for Init Azure in OSL.
  let url = getApiUrl('login', 'initAzure');
  let authCode = encrypt(`${code}:${sessionState}:${decrypt(decodeURIComponent(authState))}`);
  callAPI(url, 'post', { code: authCode }, (response) => {
    if (response.data && response.data.success) {
      cookiesManager.setCookie(cookieKeysEnum.APP_SESSIONID, sessionState);
      cb(true);
    } else {
      dispatch(setLoader(false));
      cb(false);
    }
  })
}

export const uploadFileInS3Action = (dispatch, url, postData, callback) => {
  dispatch(setLoader(true));
  delete axios.defaults.headers.common["Authorization"];
  axios.put(url, postData)
    .then((response) => {
      dispatch(setLoader(false));
      // TODO : check whether status get 200, that only file has been uploaded.
      if (response.status == 200) {
        callback(true);
      } else {
        // Handle error case
        callback(false);
        dispatch(setMessage(true, '7104'));
      }
    })
    .catch((err) => {
      callback(false);
      dispatch(setLoader(false));
      dispatch(setMessage(true, '7104'));
    });
};

export default {
  hideMessage,
  setLoader,
  setMessage,
  logout,
  getPermissionOnPageAction,
  initiateAzureRequestAction
}

