// @flow

// https://github.com/diegohaz/arc/wiki/Reducers
import authReducer from 'reducers/Auth/authReducer/reducer';
import bosVolumeReducer from 'reducers/Committee/bosVolumeReducer/reducer';
import committeeTypeReducer from 'reducers/Committee/committeeTypeReducer/reducer';
import meetingTypeReducer from 'reducers/Committee/meetingTypeReducer/reducer';
import officerTitleReducer from 'reducers/Committee/officerTitleReducer/reducer';
import commonReducer from 'reducers/Common/commonReducer/reducer';
import classificationReducer from 'reducers/Membership/classificationReducer/reducer';
import documentReducer from 'reducers/Membership/documentReducer/reducer';
import faqReducer from 'reducers/Membership/faqReducer/reducer';
import membershipType from 'reducers/Membership/membershipType/reducer';
import templateReducer from 'reducers/Membership/templateReducer/reducer';
import userReducer from 'reducers/User/userReducer/reducer';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';

// App initial state
const reducers = {
  i18nReducer,
  form,
  authReducer,
  committeeTypeReducer,
  meetingTypeReducer,
  officerTitleReducer,
  commonReducer,
  classificationReducer,
  documentReducer,
  templateReducer,
  membershipType,
  faqReducer,
  userReducer,
  bosVolumeReducer
};

export default combineReducers(reducers);
