import { SHOW_NOT_FOUND_PAGE } from 'actionTypes';
import CheckboxElement from 'components/atoms/CheckBox';
import RenderInputField from 'components/atoms/Input';
import RenderSelect from 'components/atoms/Select';
import { compareSimpleFormData, encodeText, setPermissionNavigation } from 'helpers/utilCommon';
import lodash from 'lodash';
import { USERPRIVILEGE, USERROLES } from 'models/Common/commonModels';
import React from "react";
import { Field, reduxForm } from 'redux-form';
import { Accordion, Confirm, Form, Grid, Icon } from 'semantic-ui-react';

class AddUserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0, viewType: -1, userInfo: { UserPrivelege: [] },
            defaultRole: this.props.defaultRole,
            isRecorFind: this.props.viewType > 0,
            isDuplicate: this.props.isDuplicate,
            isEdit: this.props.isEdit,
            checkboxValidation: 0,
            isConfirm: false,
            confirmType: 0,
            isPasswordRequired: true,
            isConfirmPasswordRequired: true,
            isInvalidPassword: true,
            isNotMatchedConfirmPassword: true,
            isValid: false,
            previousUserInfo: { UserPrivelege: [], isAdUserNotExist: false }
        }
    }

    loadModules = () => {
        let reqPost = { userId: this.props.location.pathname.split('/')[3] || 0 }
        this.props.roleList(reqPost, () => this.props.getUserdetailwithPrivilage(reqPost, (
            (response) => {
                let userInfoView = {
                    UserPrivelege: []
                }
                userInfoView = response.UserDetail ? response.UserDetail : userInfoView;
                userInfoView.UserPrivelege = response.UserPrivilege;
                if (userInfoView.UserPrivelege && userInfoView.UserPrivelege.length > 0) {
                    userInfoView.UserPrivelege = userInfoView.UserPrivelege.filter(item => item.ModuleName.toLowerCase() !== 'application management')
                }
                if (response.UserDetail) {

                    this.setState({
                        showViewForm: true, viewType: 1, isEdit: true, isRecorFind: true,
                        userInfo: lodash.cloneDeep(userInfoView),
                        assignedRoleId: userInfoView.RoleId ? userInfoView.RoleId : undefined,
                        previousUserInfo: lodash.cloneDeep(userInfoView)
                    });
                } else if (this.isAddPageCheck()) {
                    this.setState({
                        viewType: 0
                    })
                } else {
                    this.props.dispatch({ type: SHOW_NOT_FOUND_PAGE })

                }
            }
        )));
    }

    componentWillMount() {
        this.loadModules();
    }

    isAddPageCheck = () => {
        const { location: { pathname } } = this.props;
        if (pathname === setPermissionNavigation('user-add')) {
            return true
        }
        return false;
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }

    setData = (list) => {
        let response = []
        if (list && list.length > 0) {
            list.map((res) => {
                response.push({ value: res.RoleId, text: res.RoleName });
            });

        } return response;
    }

    onChangeRoleHandler = (e, value) => {
        let self = this;
        let reqdata = { roleId: value, userId: self.state.userInfo.UserId };
        let { userInfo } = self.state;

        userInfo.UserPrivelege = [];
        userInfo.RoleId = value;

        // Condition to handle role change from previous to new then again previous role
        if (self.state.isEdit && value === Number(self.state.assignedRoleId)) {
            // Get Privilege for Existing User
            self.props.privilegeByUser(reqdata, (() => {
                self.setState({ userInfo: this.props.userInfo })
            }))
        } else {
            self.props.privilegeByRole(reqdata, ((response) => {
                userInfo.UserPrivelege = response.filter(item => item.ModuleName !== 'Application Management');
                self.setState({ userInfo })
            }))
        }

    }

    AD_validation = (value) => {
        let self = this;

        let userInfo = self.state.userInfo;
        userInfo.UserPrivelege = [];
        userInfo.RoleId = USERROLES.ASTM_STAFF;
        let reqdata = { roleId: userInfo.RoleId }
        self.setState({ userInfo: userInfo })
        if (value.UserName) {
            value.UserName = value.UserName.trim();
            let checkDuplicate = { userName: value.UserName, type: 1 }
            self.props.getvalidateUser(checkDuplicate, ((userInfoResult) => {
                if (!userInfoResult && process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
                    this.setState({
                        isAdUserNotExist: true,
                        isRecorFind: false
                    });
                    return false;
                }
                this.setState({ isDuplicate: self.props.isDuplicate, isRecorFind: !self.props.isDuplicate })
                if (!self.props.isDuplicate) {

                    self.props.roleList(reqdata, (() => {
                        self.props.privilegeByRole(reqdata, ((response) => {
                            let adUserInfo = self.state.userInfo;
                            if (process.env.REACT_APP_LOGIN_BY_AZURE_AD === "true") {
                                adUserInfo.UserName = value.UserName;
                                adUserInfo.Email = lodash.get(userInfoResult, 'UserPrincipalName')
                                adUserInfo.FirstName = lodash.get(userInfoResult, 'GivenName')
                                adUserInfo.LastName = lodash.get(userInfoResult, 'Surname')
                                //adUserInfo.Phone = lodash.get(userInfoResult, 'BusinessPhones') && lodash.get(userInfoResult, 'BusinessPhones').length > 0 && lodash.get(userInfoResult, 'BusinessPhones')[0];
                            } else {
                                // If Done By Without Azure Ad.
                                adUserInfo.UserName = value.UserName;
                                adUserInfo.Email = value.UserName + '@yopmail.com';
                                adUserInfo.FirstName = value.UserName + 'FName';
                                adUserInfo.LastName = value.UserName + 'LName';
                                //adUserInfo.Phone = '+1 (404) 2719320519';
                            }

                            adUserInfo.UserPrivelege = response;
                            adUserInfo.UserId = self.props.userInfo.UserId || 0;
                            if (adUserInfo.UserPrivelege && adUserInfo.UserPrivelege.length > 0) {
                                adUserInfo.UserPrivelege = adUserInfo.UserPrivelege.filter(item => item.ModuleName.toLowerCase() !== 'application management')
                            }
                            self.setState({ userInfo: adUserInfo, isRecorFind: true, isAdUserNotExist: false })

                        }));
                    }))
                }
            }
            ))
        } else {
            self.setState({ isRecorFind: false });
        }
    }

    isUserPrivilegesUpdated = () => {
        try {
            const { UserPrivelege: prevUserPrivilege } = this.state.previousUserInfo;
            const { UserPrivelege: newUserPrivilege } = this.state.userInfo;

            for (let i = 0; i < prevUserPrivilege.length; i++) {
                const module = prevUserPrivilege[i];

                for (let j = 0; j < module.SubModule.length; j++) {
                    const subModule = module.SubModule[j];

                    for (let k = 0; k < subModule.Privilege.length; k++) {
                        const privilege = subModule.Privilege[k];

                        const newPrivileges = newUserPrivilege[i].SubModule[j].Privilege;

                        const matchedPrivilege = newPrivileges.find((item) => {
                            return item.SubModulePrivilegeId === privilege.SubModulePrivilegeId;
                        })

                        if (matchedPrivilege && matchedPrivilege.IsChecked !== privilege.IsChecked) {
                            return true;
                        }
                    }
                }
            }
        } catch (err) {
            return false;
        }

        return false;
    }

    submitOnEdit = () => {
        let props = this.props;
        props.putUserPrivilage(this.state.userInfo, () => {
            let self = this;
            self.setState({ viewType: 0 })
            props.history.push(setPermissionNavigation('user-list'));
        })
    }

    submitHandler = () => {
        let props = this.props;
        this.setState({ checkboxValidation: 0 })

        let count = document.querySelectorAll(".privilege input[type='checkbox']:checked").length;
        let totalcount = document.querySelectorAll(".privilege input[type='checkbox']").length;
        if (count && count > 0) {
            if (this.state.userInfo.UserId > 0) {
                let changeCount = 0;
                if (this.state.assignedRoleId === USERROLES.ADMIN && count === totalcount) {
                    this.setState({ isConfirm: true, confirmType: 1 })
                } else {
                    // Password will work Without SSO
                    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD === "false") {
                        this.comparePassword();
                        this.state.userInfo.password = this.state.password ? encodeText(this.state.password) : null;
                    }

                    if (!this.isUserPrivilegesUpdated() && compareSimpleFormData(this.state.previousUserInfo, this.state.userInfo, ['UserName', 'Email', 'FirstName', 'LastName', 'Phone', 'Status', 'RoleId']) === 0) {
                        props.displayNoChangesMadeMessage()
                        props.history.push(setPermissionNavigation('user-list'));
                    } else {
                        let tempUserInfo = this.state.userInfo.UserPrivelege
                        tempUserInfo.map(item => {
                            item.SubModule.map(item2 => {
                                item2.Privilege = item2.Privilege.filter(item3 => item3.PrivilegeId > 0)
                            })
                            return item;
                        })
                        if (!lodash.isEqual(this.state.previousUserInfo.UserPrivelege, tempUserInfo)) {
                            changeCount++;
                        }
                        if (changeCount > 0) {
                            props.putUserPrivilage(this.state.userInfo, () => {
                                let self = this;
                                self.setState({ viewType: 0 })
                                props.history.push(setPermissionNavigation('user-list'));
                            })
                        } else {
                            props.displayNoChangesMadeMessage()
                            props.history.push(setPermissionNavigation('user-list'));
                        }

                    }
                }

            } else if (process.env.REACT_APP_LOGIN_BY_AZURE_AD === "false") {
                this.comparePassword();
                if ((this.state.password && this.state.password.trim().length > 0) && (this.state.confirmPassword && this.state.confirmPassword.trim().length > 0) && (this.state.password === this.state.confirmPassword)) {
                    this.state.userInfo.password = encodeText(this.state.password);
                    props.userPrivilegeSubmit(this.state.userInfo, () => {
                        let self = this;
                        self.setState({ viewType: 0 })
                        props.history.push(setPermissionNavigation('user-list'));
                    })
                }
            } else {
                props.userPrivilegeSubmit(this.state.userInfo, () => {
                    let self = this;
                    self.setState({ viewType: 0 })
                    props.history.push(setPermissionNavigation('user-list'));
                })
            }
        } else {
            this.setState({ checkboxValidation: 1 });
        }

    }


    // Permission CheckBox Full Handler
    changePrivilege = (event, moduleIndex, subModuleIndex, privilegeIndex, option, userPrivilege) => {

        // Get User Privilege Index
        const getPrivilegeIndex = () => {
            return userPrivilege && userPrivilege.length > 0 && userPrivilege;
        }

        // Set User Privilege Index
        const setPrivilegeIndex = (selectedPrivilege) => {
            const { userInfo } = this.state;
            if (lodash(userInfo).get('UserPrivelege') && lodash(userInfo).get('UserPrivelege').length > 0) {
                if (lodash(userInfo.UserPrivelege[moduleIndex]).get('SubModule') && lodash(userInfo.UserPrivelege[moduleIndex]).get('SubModule').length > 0) {
                    userInfo.UserPrivelege[moduleIndex].SubModule[subModuleIndex].Privilege = selectedPrivilege;
                }
            } this.setState({ userInfo: userInfo });
        }

        // Privilege CheckBox handler
        const privilegeCheckBoxHandler = (selectedPrivilegeParams, checkedPermission, unCheckedPermission) => {
            // Current CheckBox Selector
            selectedPrivilegeParams[privilegeIndex].IsChecked = !selectedPrivilegeParams[privilegeIndex].IsChecked;
            // Selected Index With Checked CheckBox Permissions
            if (selectedPrivilegeParams[privilegeIndex].IsChecked) {
                for (let i = 0; i < selectedPrivilegeParams.length; i++) {
                    if (checkedPermission.indexOf(lodash(selectedPrivilegeParams[i]).get('SubModulePrivilegeKey')) > -1) {
                        if (selectedPrivilegeParams[i].IsEditable) {
                            selectedPrivilegeParams[i].IsChecked = true // Checked True
                        }
                    }
                }
            } else { // Selected Index With UnChecked CheckBox Permissions
                for (let i = 0; i < selectedPrivilegeParams.length; i++) {
                    if (unCheckedPermission.indexOf(lodash(selectedPrivilegeParams[i]).get('SubModulePrivilegeKey')) > -1) {
                        if (selectedPrivilegeParams[i].IsEditable) {
                            selectedPrivilegeParams[i].IsChecked = false // Checked False
                        }
                    }
                }
            }
            // Return Modified Items
            return selectedPrivilegeParams;
        }

        // Get Selected Index While Clicked On CheckBox
        let selectedPrivilege = getPrivilegeIndex();
        let checkedOptions = [];
        switch (option) {
            case USERPRIVILEGE.All:
                checkedOptions = privilegeCheckBoxHandler(selectedPrivilege, [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.ADD, USERPRIVILEGE.UPDATE, USERPRIVILEGE.DELETE], [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.ADD, USERPRIVILEGE.UPDATE, USERPRIVILEGE.DELETE]);
                break;
            case USERPRIVILEGE.VIEW:
                checkedOptions = privilegeCheckBoxHandler(selectedPrivilege, [USERPRIVILEGE.VIEW], [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.ADD, USERPRIVILEGE.UPDATE, USERPRIVILEGE.DELETE]);
                break;
            case USERPRIVILEGE.ADD:
                checkedOptions = privilegeCheckBoxHandler(selectedPrivilege, [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.ADD], []);
                break;
            case USERPRIVILEGE.UPDATE:
                checkedOptions = privilegeCheckBoxHandler(selectedPrivilege, [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.UPDATE], []);
                break;
            case USERPRIVILEGE.DELETE:
                checkedOptions = privilegeCheckBoxHandler(selectedPrivilege, [USERPRIVILEGE.All, USERPRIVILEGE.VIEW, USERPRIVILEGE.UPDATE, USERPRIVILEGE.DELETE], []);
                break;
            default:
                break;
        }
        // Set User Privilege CheckBox Options
        setPrivilegeIndex(checkedOptions);
    }


    enableEdit = () => {

        this.setState({ viewType: 2 })
    }
    userNameValidation = value => {
        this.setState({ isDuplicate: false, isAdUserNotExist: false });
        let validchar = /^\S+$/
        if (value) {
            if (!validchar.test(value)) {
                return this.props.userNameSpaceNotAllow
            }
        }
    }


    // Render Configurable User Privilege
    configureUserPrivileges = (privilege, index, subIndex, submoduleId) => {
        // Get All options Editable except All
        const getEditableForAllOptions = (privilegeList) => {
            let viewOptions = privilegeList && privilegeList.filter((filterItem) => filterItem.SubModulePrivilegeKey !== USERPRIVILEGE.All && filterItem.IsEditable == false);
            if (privilegeList && privilegeList.length > 0 && viewOptions && viewOptions.length > 0 && (privilegeList.length - 1) === viewOptions.length) {
                return false;
            }
            return true;
        }

        // Set All Options In JSON List
        const setAllOptionsToPrivilege = (privilegeList, subModulePrivilegeId) => {
            if (privilegeList.filter(_item => _item.SubModulePrivilegeKey === "All").length === 0) {
                privilegeList.push({ "SubModulePrivilegeKey": "All", "SubModulePrivilegeId": -subModulePrivilegeId, "PrivilegeId": -1, "PrivilegeName": "All", "IsEditable": false, "IsChecked": true });
            }
            return privilegeList;
        }
        // Determined All Check Options
        const getCheckedOptions = (privilegeList) => {
            let counter = 0;
            if (privilegeList && privilegeList.length > 0) {
                privilegeList.map((checkedOptions) => {
                    if (checkedOptions.IsChecked) {
                        counter++
                    }
                });
                if (privilegeList.length === counter) { return true }
            }
            return false;
        }

        // Render Functional View For User Privilege
        let userPrivilege = setAllOptionsToPrivilege(privilege, submoduleId);
        return userPrivilege.sort((a, b) => (a.PrivilegeId > b.PrivilegeId) ? 1 : -1).map((_privilegeOptions, _index) => {
            if (_privilegeOptions.PrivilegeId === -1) {
                _privilegeOptions.IsEditable = getEditableForAllOptions(userPrivilege);
                _privilegeOptions.IsChecked = getCheckedOptions(userPrivilege)
            }
            return <React.Fragment>
                <Grid.Column>
                    <CheckboxElement data-testid="checkAccordian" className="checkAccordian privilege" checked={_privilegeOptions.IsChecked} setLabeltitleProp={_privilegeOptions.PrivilegeName} {..._privilegeOptions} onchange={(event, data = { index, subIndex, _index }) => this.changePrivilege(event, index, subIndex, _index, _privilegeOptions.SubModulePrivilegeKey, userPrivilege)}></CheckboxElement>
                </Grid.Column>
                {(_index === 3 && privilege.length === 4) ? <Grid.Column></Grid.Column> : ''}
                {(_index === 2 && privilege.length === 3) ? <React.Fragment><Grid.Column></Grid.Column><Grid.Column></Grid.Column></React.Fragment > : ''}
            </React.Fragment>
        })
    }

    onCancel = () => {
        this.setState({ isConfirm: false, confirmType: 0 })
    }

    backtoListPage = () => {
        let self = this;
        self.setState({ viewType: 0 });
        self.props.history.push(setPermissionNavigation('user-list'));
    }

    userNameRequired = value => {
        this.setState({ isDuplicate: false, isAdUserNotExist: false })
        return value ? undefined : this.props.userNameRequired;
    }

    validUserName = value => {
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD === "false") {
            if (!value.match(/^[a-z0-9]+$/i)) {
                return this.props.userNameInvalid;
            }
        }
    }

    // Validate Password Pattern
    validatePassword = value => {
        let exp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{7,16}$/;
        this.setState({ password: value });
        if (!value || (value && value.trim().length === 0)) {
            this.setState({
                checkboxValidation: 0, isPasswordRequired: false, isConfirmPasswordRequired: false,
                isInvalidPassword: false, isNotMatchedConfirmPassword: false
            }, () => this.setState({ isPasswordRequired: true }));
        }
        else if (!exp.test(value)) {
            this.setState({
                checkboxValidation: 0, isPasswordRequired: false, isConfirmPasswordRequired: false,
                isInvalidPassword: false, isNotMatchedConfirmPassword: false
            }, () => this.setState({ isInvalidPassword: true }));
        }
    }

    // Validate Confirm Password
    validateConfirmPasswordPassword = value => {
        this.setState({ confirmPassword: value });
        if (!value || (value && value.trim().length === 0)) {
            this.setState({
                checkboxValidation: 0, isPasswordRequired: false, isConfirmPasswordRequired: false,
                isInvalidPassword: false, isNotMatchedConfirmPassword: false
            }, () => this.setState({ isConfirmPasswordRequired: true }));
        } else {
            this.setState({
                checkboxValidation: 0, isPasswordRequired: false, isConfirmPasswordRequired: false,
                isInvalidPassword: false, isNotMatchedConfirmPassword: false
            });
        }
    }

    comparePassword = () => {
        this.validatePassword(this.state.password);
        this.validateConfirmPasswordPassword(this.state.confirmPassword);
        if (this.state.password && this.state.password.trim().length > 0 && this.state.confirmPassword && this.state.confirmPassword.trim().length > 0) {
            this.setState({
                checkboxValidation: 0, isPasswordRequired: false, isConfirmPasswordRequired: false,
                isInvalidPassword: false, isNotMatchedConfirmPassword: false
            }, () => this.setState({ isNotMatchedConfirmPassword: true }));
        }
    }

    isPasswordVisible = () => {
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD === "false") {
            return true
        }
        return false;
    }

    render() {
        const { activeIndex, userInfo, viewType, isRecorFind, isDuplicate, isEdit, checkboxValidation, isConfirm,
            isPasswordRequired, isConfirmPasswordRequired, isInvalidPassword, isNotMatchedConfirmPassword, isAdUserNotExist } = this.state;
        const { submitting, passwordRequired, passwordInvalid, confirmPasswordRequired,
            confirmPasswordNotMatched, userPermission } = this.props;
        let isPasswordVisible = this.isPasswordVisible();
        let userNotFoundMessage = lodash.get(this.props, "messageCodes") && lodash.get(this.props, "messageCodes")["2515"];
        console.log('userPRvilage', userInfo)
        return (

            <section data-testid="addUser" className={viewType === 1 || viewType === 2 ? "mainWrapperRight bg" : ''}>
                <div className="headingTitle clearfix mb20">
                    {viewType === 0 &&
                        <h2>Add User</h2>
                    }
                    {viewType === 1 &&
                        <h2>View User</h2>
                    }
                    {viewType === 2 &&
                        <h2>Edit User</h2>
                    }
                </div>

                <div className="validateAD" style={{ 'display': isEdit || viewType === -1 ? 'none' : '' }}>
                    <Form size='large' onSubmit={this.props.handleSubmit(this.AD_validation)} id="searchUser">
                        <Grid columns='equal'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="UserName" type="text"
                                        component={RenderInputField} label="Search by Username"
                                        validate={[this.userNameRequired, this.userNameValidation, this.validUserName]}
                                        required='true'
                                        autoFocus='true'
                                        maxLength="70"
                                    />
                                    {isDuplicate && <span className="errorMessage mt0">{isDuplicate ? this.props.userNameExist : ''}</span>}
                                    {isAdUserNotExist && <span className="errorMessage mt0">{isAdUserNotExist ? lodash.get(userNotFoundMessage, "text") : ''}</span>}
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="label mb5">&nbsp;</label>
                                    <button data-testid="validateInADButtoClick" className="ui button primary" type="submit" disabled={submitting} >Validate in AD</button>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Form >
                </div>
                <div className="userRelatedInfo" style={{ display: (!isRecorFind ? 'none' : '') }}>
                    <Grid columns='3'>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="titleLabel">Username</span>
                                <span className="titleInfo">{userInfo.UserName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="titleLabel">Email</span>
                                <span className="titleInfo"><a href={"mailto:" + userInfo.Email} target="_top">{userInfo.Email}</a></span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="titleLabel">First Name</span>
                                <span className="titleInfo">{userInfo.FirstName}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="titleLabel">Last Name</span>
                                <span className="titleInfo">{userInfo.LastName}</span>
                            </Grid.Column>
                            {/* <Grid.Column>
                                <span className="titleLabel">Phone</span>
                                <span className="titleInfo">{userInfo.Phone}</span>
                            </Grid.Column> */}

                        </Grid.Row>

                    </Grid>
                </div>
                <div className="validateAD" style={{ display: (!isRecorFind ? 'none' : '') }} data-testid="validateAD">
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column className="pt5">
                                <span className="titleLabel">User Type</span>
                            </Grid.Column>
                            {isEdit && viewType === 1 ? (
                                <Grid.Column>
                                    {userInfo.RoleName}
                                </Grid.Column>
                            ) : (
                                    <Grid.Column>
                                        <RenderSelect placeholder="ASTM Staff" onchange={this.onChangeRoleHandler} value={parseInt(userInfo.RoleId)} options={this.setData(this.props.roledata)} />
                                    </Grid.Column>
                                )}
                            <Grid.Column></Grid.Column>
                        </Grid.Row>

                    </Grid>
                    {
                        (viewType === 1 && isEdit && userPermission.Update) ?
                            <span data-testid="enableHandleClick" className="editBtn"><Icon name="pencil" onClick={() => this.enableEdit()} /></span> : ''
                    }

                    {
                        !isEdit && isPasswordVisible && (userInfo.UserPrivelege && userInfo.UserPrivelege.length > 0) && < Grid columns='equal' > {/* Added User Password Implementation */}
                            <Grid.Row>
                                <Grid.Column className="pt5">
                                    <span className="titleLabel">Password</span>
                                </Grid.Column>

                                <Grid.Column>
                                    <Field name="password" type="password"
                                        component={RenderInputField} label=""
                                        validate={this.validatePassword}
                                        autoFocus='true'
                                    />
                                    {isPasswordRequired && isPasswordRequired && <span className="errorMessage mt0">{passwordRequired}</span>}
                                    {isInvalidPassword && isInvalidPassword && <span className="errorMessage mt0">{passwordInvalid}</span>}
                                </Grid.Column>
                                <Grid.Column></Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className="pt5">
                                    <span className="titleLabel">Confirm Password</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="ConfirmUserPassword" type="password"
                                        component={RenderInputField} label=""
                                        autoFocus='true' validate={this.validateConfirmPasswordPassword}
                                    />
                                    {isConfirmPasswordRequired && isConfirmPasswordRequired && <span className="errorMessage mt0">{confirmPasswordRequired}</span>}
                                    {isNotMatchedConfirmPassword && isNotMatchedConfirmPassword && <span className="errorMessage mt0">{confirmPasswordNotMatched}</span>}
                                </Grid.Column>
                                <Grid.Column></Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                </div >
                {(userInfo.UserPrivelege && userInfo.UserPrivelege.length > 0) ?
                    <div className="mt30">
                        <h5 className="mb10">Privileges</h5>

                        <Accordion data-testid="accordianPrivilage" fluid>
                            {userInfo.UserPrivelege.filter(item => item.ModuleName.toLowerCase() !== 'application management').map((item, index) => {
                                return <div>
                                    <Accordion.Title active={activeIndex === index} index={index} id={item.ModuleName + item.ModuleId} onClick={this.handleClick}>
                                        <Icon name='dropdown down' />
                                        {item.ModuleName}
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === index} style={{ pointerEvents: (isEdit && viewType === 1) ? 'none' : '' }}>
                                        {
                                            (item.SubModule) ? item.SubModule.map((subItem, subIndex) => {
                                                return <div>
                                                    <p className="commonTitle">{subItem.SubModuleName}</p>
                                                    <div className="checkBoxWrapper">
                                                        <Grid columns='equal'>
                                                            <Grid.Row>
                                                                {subItem.Privilege && this.configureUserPrivileges(subItem.Privilege, index, subIndex, subItem.SubModuleId)
                                                                }

                                                            </Grid.Row>

                                                        </Grid>
                                                    </div>
                                                </div>


                                            }) : ''
                                        }
                                    </Accordion.Content>

                                </div>
                            })}
                        </Accordion>
                        {checkboxValidation > 0 && <span className="errorMessage"> {this.props.privilegeAssignedRequired} </span>}
                        {!(isEdit && viewType === 1) && (
                            <div>
                                <button data-testid="submitHandlerResponse" className="ui button primary mr10" type="submit" onClick={() => this.submitHandler()} >{viewType == 0 ? 'Save' : 'Update'}</button>
                                <button className="ui button cancel" onClick={this.backtoListPage}>Cancel</button>
                            </div>
                        )
                        }
                    </div> : ''
                }

                <Confirm className="confirmBox" cancelButton='No' confirmButton="Yes" content='Do you want to assign admin permissions to the User?'
                    onCancel={this.onCancel} onConfirm={this.submitOnEdit} open={isConfirm} />
            </section >
        )
    }
}

export default (reduxForm({
    form: 'AddUserForm'
})(AddUserForm));
